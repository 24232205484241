.about{
    width: 100%;
    margin: 3rem 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    height: 100%;
}
.about.left{
    text-align: center;
    margin: auto;
    padding: 1rem;
    max-width: 350px;
}
.about p{
    margin: 0.2rem 0;
}
.about button{
    margin: 1rem 0;
    padding: 9px;
}
h3 {
    font-size: 2.4rem;
      font-weight: 800;
      margin-top: 150%;
      margin-left: 9%;
      /* margin: 50%; */
      align-items: center;
      justify-content: center;
  }

  @media screen and (max-width: 768px) {
    h3 {
      font-size: 2.4rem;
        font-weight: 800;
        margin-top: 80%;
        margin-left: 9%;
        align-items: center;
        justify-content: center;
    }

  }
  

.content{
    position: absolute;
    transform: translate(-50%,-50%);
    top: 50%;
    left: 50%;
    text-align: center;
}

/* spinner loader */
.spinner {
    margin: auto;
    position: relative;
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    /* margin-left: 50px; */
  }
  
  .spinner span {
    position: absolute;
    top: 50%;
    left: var(--left);
    width: 35px;
    height: 7px;
    background: #ffff;
    animation: dominos 1s ease infinite;
    box-shadow: 2px 2px 3px 0px black;
  }
  
  .spinner span:nth-child(1) {
    --left: 80px;
    animation-delay: 0.125s;
  }
  
  .spinner span:nth-child(2) {
    --left: 70px;
    animation-delay: 0.3s;
  }
  
  .spinner span:nth-child(3) {
    left: 60px;
    animation-delay: 0.425s;
  }
  
  .spinner span:nth-child(4) {
    animation-delay: 0.54s;
    left: 50px;
  }
  
  .spinner span:nth-child(5) {
    animation-delay: 0.665s;
    left: 40px;
  }
  
  .spinner span:nth-child(6) {
    animation-delay: 0.79s;
    left: 30px;
  }
  
  .spinner span:nth-child(7) {
    animation-delay: 0.915s;
    left: 20px;
  }
  
  .spinner span:nth-child(8) {
    left: 10px;
  }
  
  @keyframes dominos {
    50% {
      opacity: 0.7;
    }
  
    75% {
      -webkit-transform: rotate(90deg);
      transform: rotate(90deg);
    }
  
    80% {
      opacity: 1;
    }
  }

  /* card about me start */
  .abtmespacecard {
    margin: auto;
    /* margin-top: 30%; */
    width: 17em;
    height: 22.5em;
    background: #4f4f4f;
    transition: 1s ease-in-out;
    clip-path: polygon(30px 0%, 100% 0, 100% calc(100% - 30px), calc(100% - 30px) 100%, 0 100%, 0% 30px);
    border-top-right-radius: 20px;
    border-bottom-left-radius: 20px;
    display: flex;
    flex-direction: column;
  }
  
  .abtmespacecard span {
    font-weight: bold;
    color: white;
    text-align: center;
    display: block;
    font-size: 1em;
    /* height: max-content; */
  }

 
  
  
  .abtmespacecard .abtmespaceinfo {
    font-weight: 400;
    color: white;
    display: block;
    text-align: center;
    font-size: 0.72em;
    margin: 1em;
  }
  
  .abtmespacecard .abtmespaceimg {
    width: 4.8em;
    height: 4.8em;
    background: #4f4f4f;
    border-radius: 15px;
    margin: auto;
  }

  
  
  
  .abtmespacecard .abtmespaceshare {
    margin-top: 1em;
    display: flex;
    justify-content: center;
    gap: 1em;
  }
  
  .abtmespacecard a {
    color: white;
    transition: .4s ease-in-out;
  }
  
  .abtmespacecard a:hover {
    color: red;
  }
  
  .abtmespacecard button {
    padding: 0.8em 1.7em;
    display: block;
    margin: auto;
    border-radius: 25px;
    border: none;
    font-weight: bold;
    background: #ffffff;
    color: rgb(0, 0, 0);
    transition: .4s ease-in-out;
  }
  
  .abtmespacecard button:hover {
    background: red;
    color: white;
    cursor: pointer;
  }
  /* card about me end */


  /* edueducards */
  .educards {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  .educards .red {
    background-color: #f43f5e;
  }
  
  .educards .blue {
    background-color: #3b82f6;
  }
  
  .educards .green {
    background-color: #22c55e;
  }
  
  .educards .educard {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    height: 100px;
    width: 250px;
    border-radius: 10px;
    color: white;
    cursor: pointer;
    transition: 400ms;
  }
  
  .educards .educard p.tip {
    font-size: 1em;
    font-weight: 700;
  }
  
  .educards .educard p.second-text {
    font-size: .7em;
  }
  
  .educards .educard:hover {
    transform: scale(1.1, 1.1);
  }
  
  .educards:hover > .educard:not(:hover) {
    filter: blur(10px);
    transform: scale(0.9, 0.9);
  }

/* phone view */
  @media screen and (max-width: 768px) {
    .educards {
      display: flex;
      margin: auto;
      margin-left: 10%;
      flex-direction: column;
      gap: 15px;
    }
    
    .educards .red {
      background-color: #484848;
    }
    
    .educards .blue {
      background-color: #484848;
    }
    
    .educards .green {
      background-color: #484848;
    }
    
    .educards .educard {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      text-align: center;
      height: 100px;
      width: 250px;
      border-radius: 10px;
      color: white;
      cursor: pointer;
      transition: 400ms;
    }
    
    .educards .educard p.tip {
      font-size: 1em;
      font-weight: 700;
    }
    
    .educards .educard p.second-text {
      font-size: .7em;
    }
    
    .educards .educard:hover {
      transform: scale(1.1, 1.1);
    }
    
    .educards:hover > .educard:not(:hover) {
      filter: blur(10px);
      transform: scale(0.9, 0.9);
    }  
  }

.mask{
    width: 100;
    height: 120vh;
    /* position: relative; */
}
.into-img{
  /* position: absolute; */
  /* z-index: ; */
  margin: auto;
  /* margin-top: -90px; */
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    object-fit: cover;
    background-color: #e8e8e8; /* Set background color for hero section */
    position: relative;
}

.mask::after {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #e8e8e8;
    opacity: 0.8;
}

.hero {
    height: 100%;
    width: 100%;
    background-color: #e8e8e8;

}
.first-container{
  /* margin-top: 50px; */
  display: flex;

  width: 100%;
  /* height: 290px; */
  background-color: #0974f1;
}

.hero .content{
  position: absolute;
  margin-top: -120px;
  display: flex;
  flex-direction: column;
  /* background-color: #000; */
  z-index: 1;
}





.hero .content{
    display: flex;
    flex-direction: column;

    /* position: relative; */
    transform: translate(-30%,-70%);
    /* top: 50%; */
    left: 20%;
    text-align: center;
    
}


.hero .content h1{
    font-size: 6rem;
    padding: 0.6rem 0 1.5rem;
}

.initial-container{
  /* background-color: #5c5c5c; */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.name-container{
  /* background-color: #222; */
  min-width: 50%;
  margin-right: 10%;
  margin-left: 10%;
}

.profile-image{
  margin-top: 150px;
  margin-right: 50%;
}



@import url('https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@700&display=swap');

.hero .initial-container .name-container p {
  font-family: 'Space Grotesk', sans-serif;
  font-size: 4.8rem;
  font-weight: 700;
  color: rgb(62, 62, 62);
  position: relative;
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  margin: 0 auto;
  letter-spacing: -0.03em;
  text-transform: uppercase;
  
  /* Dotted text effect */
  background-image: linear-gradient(90deg, #2c3e50 50%, transparent 50%),
                    linear-gradient(90deg, #2c3e50 50%, transparent 50%);
  background-size: 12px 2px, 12px 2px;
  background-position: 0 0, 0 100%;
  background-repeat: repeat-x;
  -webkit-background-clip: text;
  background-clip: text;
  
  /* Typing animation */
  width: 0;
  animation: 
    typing 3s steps(20, end) forwards,
    dotted-text-reveal 3s steps(20, end) forwards;
}

@keyframes typing {
  from { 
    width: 0;
  }
  to { 
    width: 100%; 
  }
}

@keyframes dotted-text-reveal {
  from { 
    background-size: 0 2px, 0 2px;
  }
  to { 
    background-size: 12px 2px, 12px 2px;
  }
}

/* Cursor effect */
.hero .initial-container .name-container p::after {
  content: '|';
  position: absolute;
  right: 0;
  color: #2c3e50;
  animation: blink 0.7s infinite;
}

@keyframes blink {
  0%, 100% { opacity: 0; }
  50% { opacity: 1; }
}

/* Responsive adjustments */
@media screen and (max-width: 768px) {
  .hero .initial-container .name-container p {
    font-size: 3.2rem;
    background-size: 8px 2px, 8px 2px;
  }
}

@media screen and (max-width: 480px) {
  .hero .initial-container .name-container p {
    font-size: 2.4rem;
    background-size: 6px 2px, 6px 2px;
  }
}
.initial-container .name-container{
  margin-top: 150px;
}

/* @keyframes blink-caret {
  from, to {
    border-color: transparent;
  }
  50% {
    border-color: rgb(67, 67, 67);
  }
} */

/* ////////////////////////////////////////////////////////// */
@media screen and (max-width: 768px) {

  .mask{
    width: 100%;
    background-color: #e8e8e8;
    /* background-color: #ff98a2; */
    height: 80vh;
    z-index: 0;
  }



  .mask::after {
    display: none;
  }

  .hero {
    height: 100%;
      width: 100%;
    background-color: #e8e8e8;
  }

  
  .hero .content {
      transform: none; /* Reset transform for mobile */
      top: 20%;
      left: 20%;
      text-align: centre;
      padding: 0 20px; /* Add padding to center content */
  }

  .hero .content h1{
    font-size: 3rem;
    padding: 0.6rem 0 1.5rem;
}

.hero .content p{
    margin-left:-20px;
    font-size: 2.4rem;
    font-weight: 200;
    
}


.profile-img{
  margin-left: 50%;
  transform: translateY(-10%); 
  animation: jump 2s infinite;
  filter: drop-shadow(10px 10px 10px rgba(0, 0, 0, 10));
  width: 250px; /* Set desired width */
  height: 250px; /* Set desired height */

}


}
/* //////////////////////////////////////////////////////////// */


.content .btn{
    margin: 1rem 0.2rem;
}

button {
  color: #090909;
  padding: 0.7em 1.7em;
  font-size: 18px;
  border-radius: 0.5em;
  background: #e8e8e8;
  cursor: pointer;
  border: 1px solid #e8e8e8;
  transition: all 0.3s;
  box-shadow: 6px 6px 12px #c5c5c5, -6px -6px 12px #ffffff;
}

button:hover {
  border: 1px solid white;
}

button:active {
  box-shadow: 4px 4px 12px #c5c5c5, -4px -4px 12px #ffffff;
}


@media screen and (max-width:768px){

    .hero{
      width: 100vh;
      min-width: 100%;
    }

    .hero .initial-container{
      display: flex;
      flex-direction: column;
      /* background-color: #b49c9c; */
      justify-content: space-between;
      align-items: center;
      justify-content: center;
      margin-bottom: 50px;
    }

    .hero .initial-container .name-container{
      margin-top: 90px;
      max-height: fit-content;
    }
    .hero .initial-container .profile-image{
      max-height: fit-content;
      margin-top: 0px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .hero .initial-container .profile-image img{
      width: 200px;
      height: 200px;
      display: flex;
      margin-left: 160%;
      justify-content: center;
      border-radius: 50%;
      align-items: center;
    }

    .hero .initial-container .name-container p{
      font-size: 2.5rem;
    }

    .hero .content h1{
        font-size: 3rem;
    }
    
    .hero .content p{
        font-size: 1.4rem;
    }
    
}

.container {
    display: flex;
    justify-content:center; /* Aligns items to the left */
}

.dropcard {
    width: 120px;
    height: 100px;
    background-color: transparent;
    box-shadow: inset 10px 10px 10px 10px rgba(0, 0, 0, 0.158),
      10px 10px 20px rgba(0, 0, 0, 0.466),
      inset -10px -10px 10px 10px rgba(255, 255, 255, 0.897);
    border-radius: 51% 49% 62% 38% / 25% 57% 43% 75%;
    position: relative;
}

.dropcard::after, .dropcard::before {
    content: "";
    display: inline-block;
    position: absolute;
    background-color: rgba(255, 255, 255, 0.842);
}

.dropcard::after {
    width: 10px;
    height: 10px;
    border-radius: 80% 20% 91% 9% / 48% 67% 33% 52%;
    top: 40px;
    left: 40px;
}

.dropcard::before {
    width: 20px;
    height: 20px;
    border-radius: 48% 52% 45% 55% / 48% 67% 33% 52%;
    top: 35px;
    left: 60px;
}
/* HeroImgStyles.css */

/* Existing styles */

.animated-text {
    font-size: 3rem;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    color: #090909;
    padding: 0.7em 1.7em;
    background: #e8e8e8;
    border-radius: 0.5em;
    display: inline-block;
    margin-bottom: 20px;
    text-decoration: none;
    border: 1px solid #e8e8e8;
    box-shadow: 6px 6px 12px #c5c5c5, -6px -6px 12px #ffffff;
    animation: bounce 1.5s infinite;
}

@keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
        transform: translateY(0);
    }
    40% {
        transform: translateY(-20px);
    }
    60% {
        transform: translateY(-10px);
    }
}

/* New styles for the specific <p> tag */

.hero .content p {
    font-size: 2.5rem; /* Increase font size */
    font-weight: bold; /* Make text bold */
    color: #333; /* Change text color */
}



.profile-img{
    /* position: absolute; */
    /* position: absolute; */
    /* z-index: 0; */
    /* margin-left: 100px; */
     /* top: -30%; 
    left: 50%;   */
    /* margin: auto; */
    /* margin-top: -50%; */
    /* margin-left: 50%; */
    transform: translateY(-10%); 
    animation: jump 2s infinite;
    filter: drop-shadow(10px 10px 10px rgba(0, 0, 0, 10));
    width: 250px; /* Set desired width */
    height: 250px; /* Set desired height */

}

@media screen and (max-width: 1024px) {
    .profile-img {
        width: 160px; /* Further reduced width for smaller screens */
        height: 160px; /* Further reduced height for smaller screens */
        top: 5%; /* Adjusted top positioning */
        right: 5%; /* Adjust distance from right edge */
    }
}





@keyframes jump {
    0%, 100% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-15px); /* Reduced bounce height */
    }
}



.aboutcard {
    position:relative;
    display:flex;
    align-items: center;
    justify-content: center;
    width: 650px;
    height: 400px;
    /* margin-top: -190px; */
    margin-left: auto;
    margin-right: auto;
    box-shadow: 0 10px 20px rgba(116, 116, 116, 0.2);
    padding: 12px;
    overflow: hidden;
    border-radius: 10px;
    background: #e8e8e8;
    border: 1px solid #e8e8e8;
    /* border: 2px solid #717171; */
    transition: all 0.5s cubic-bezier(0.23, 1, 0.320, 1);
    box-shadow: 6px 6px 12px #c5c5c5, -6px -6px 12px #ffffff;
}

@media screen and (max-width: 768px) {



  .aboutcard {
    /* position:relative; */
    display:flex;
    align-items: center;
    justify-content: center;
    width: 90%;
    height: 300px;
    /* margin-top: -190px; */
    margin-left: auto;
    margin-right: auto;
    box-shadow: 0 10px 20px rgba(116, 116, 116, 0.2);
    padding: 12px;
    overflow: hidden;
    border-radius: 10px;
    background: #e8e8e8;
    border: 1px solid #e8e8e8;
    transition: all 0.5s cubic-bezier(0.23, 1, 0.320, 1);
    box-shadow: 6px 6px 12px #c5c5c5, -6px -6px 12px #ffffff;
}

.cardcontent {
  display: flex;
  flex-direction: column;
  align-items:center;
  gap: 15px;
  color: #403f3f;
  transition: all 0.5s cubic-bezier(0.23, 1, 0.320, 1);
}

.cardcontent .cardheading {
  font-weight: 60;
  color: #333;
  font-size: 15px;
  align-items: center;
}

.cardcontent .cardpara {
  line-height: 1;
  color: #424141;
  align-items: center;
}
    
}



.aboutcard:hover {
    box-shadow: 0 0 20px rgba(9, 117, 241, 0.8);
    border-color: #0974f1;
}

.cardcontent {
    display: flex;
    flex-direction: column;
    align-items:center;
    gap: 20px;
    color: #403f3f;
    transition: all 0.5s cubic-bezier(0.23, 1, 0.320, 1);
}

.cardcontent .cardheading {
    font-weight: 70;
    color: #333;
    font-size: 18px;
    align-items: center;
}

.cardcontent .cardpara {
    line-height: 1.5;
    color: #424141;
    align-items: center;
}

.cardcontent .cardbtn {
    color: #e8e8e8;
    text-decoration: none;
    padding: 10px;
    font-weight: 600;
    border: none;
    cursor: pointer;
    background: #0974f1;
    border-radius: 5px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}

.cardcontent .cardbtn:hover {
    outline: 2px solid #e8e8e8;
    background: transparent;
    color: #e8e8e8;
}

.cardcontent .cardbtn:active {
    box-shadow: none;
}

/* Time line education */

*, *::after, *::before {
    box-sizing: border-box;
    
    margin: 0;
    padding: 0;
  }
  
  p {
    line-height: 1.8;
  }
  
  time {
    line-height: 1.6;
  }
  
  body {
    min-width: 375px;
  
    font-family: "Poppins", sans-serif;
  
    background-color: #f1f1f1;
    color: #1a1a1a;
  }
  
  main {
    min-height: 100vh;
    
    display: grid;
    place-content: center;
    
    padding : 1rem;
  }
  
  /* ===================== */
  /* ===================== */
  
  /* Timeline */
  /* Code Here ↓ */

  .education-heading{
    font-size: 2.5rem; /* Increase font size */
    font-weight: bold; /* Make text bold */
    color: #333; 
    display: flex;
    margin-top: 220px;
    justify-content: center;
    align-items: center;

  }
 .formore{
    margin: auto;
    margin-top: 17px;
    font-size: 1rem;
    align-items: center;
    margin-left: 530px;
 }
  
  .timeline {
    padding: 3rem 2rem;
    
    max-width: 460px;
    border-radius: 12px;
    /* display: flex; */
    justify-content: center; 
    align-items: center;
    margin: auto;
    margin-top: 20px;
    
    /* background-color: white; */
    /* box-shadow: 0 4px 25px -20px rgba(0,0,0.2); */
    background: #e8e8e8;
    border: 1px solid #e8e8e8;
    box-shadow: 6px 6px 12px #c5c5c5, -6px -6px 12px #ffffff;
  }
  
  .tl-content .tl-header, 
  .tl-content .tl-body {
    padding-left: 25.6px;
    
    border-left: 3px solid gainsboro;
  }
  
  .tl-body {
    padding-bottom:1rem;
  }
  
  .tl-content:last-child .tl-body {
    border-left: 3px solid transparent;
  }
  
  .tl-header {
    position: relative;
    display: grid;
    
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  
  .tl-title {
    font-weight: 600;
    font-size: 1em;
    
    border-bottom: 1px solid gainsboro;
  }
  
  .tl-time {
    font-size: 0.7em;
  }
  
  .tl-marker {
    display:block;
    position: absolute;
  
    width: 16px;
    height: 16px;
    border-radius: 50% / 50%;
    
    background: gainsboro;
    
    left: -1.1rem;
    top: 50%;
    
    transform: translate(50%,-50%);
  }
  
  .tl-content-active .tl-marker {
    padding: 1.6px;
    
    left: -1.25rem;
    
    width: 18px;
    height: 18px;
    
    border: 2px solid limegreen;
    
    background-color: limegreen;
    background-clip: content-box;
    
    box-shadow: 0 0 15px -2px limegreen;
  }
  
  .tl-content-active .tl-title {
    font-weight: 700;
    
    color: green;
  }
  
  /* Code Here ↑ */
  /* Timeline */
  
  /* ==================== */

  /* Projects */

  
  
  /* icon button */
  .button-icon {
    margin: auto;
    margin-top: 6%;
    display: flex;
    /* border: 3px #fff solid; */
    border: 1px solid #e8e8e8;
    box-shadow: 6px 6px 12px #c5c5c5, -6px -6px 12px #ffffff;
    width: fit-content;
    height: fit-content;
    cursor: pointer;
  }
  
  .icon {
    background-color: #fff;
    padding: 10px 10px 5px 10px;
  }
  
  .icon svg {
    width: 25px;
    height: 25px;
  }
  
  .cube {
    transition: all 0.4s;
    transform-style: preserve-3d;
    width: 200px;
    height: 30px;
  }
  
  .button-icon:hover {
    border-color: #0f0f0f;
  }
  
  .button-icon:hover .cube {
    transform: rotateX(90deg);
  }
  
  .side {
    position: absolute;
    height: 47px;
    width: 200px;
    display: flex;
    font-size: 0.8em;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    font-weight: bold;
  }
  
  .top {
    background: #ff98a2;
    height: 40px;
    /* margin-top: -80px; */
    color: #fff;
    transform: rotateX(-90deg) translate3d(0, 13.5px, 2em);
  }
  
  .front {
    background: #222229;
    height: 44px;
    color: #fff;
    transform: translate3d(0, 0, 1em);
  }
  
  /* icon button */

  .projectcontainer {
    /* overflow: auto; */
    height: auto;

    display: flex;
    scroll-snap-type: x mandatory;
    width: 90%;
    margin: auto;
    justify-content: center;
    /* margin-left: ; */
    padding: 0 15px;
  }


  .projectcard {
    --font-color: #323232;
    --bg-color: #e0e0e0;
    
    width: 250px;
    /* margin: auto; */
    margin-top: 5%;
    margin-left: 2%;
    height: 350px;
    border-radius: 20px;
    background: var(--bg-color);
    box-shadow: -9px 9px 18px #5a5a5a,
                9px -9px 18px #ffffff;
    display: flex;
    flex-direction: column;
    transition: .4s;
    position: relative;
  }
  
  @media screen and (max-width: 768px) {
    .projectcard {
      --font-color: #323232;
      --bg-color: #e0e0e0;
      /* --bg-color: #6e0f0f; */
      width: 250px;
      margin-top: 5%;
      margin-left: 2%;
      height:max-content;
      min-height: 400px;
      border-radius: 20px;
      background: var(--bg-color);
      box-shadow: -9px 9px 18px #5a5a5a,
                  9px -9px 18px #ffffff;
      display: flex;
      flex-direction: column;
      transition: .4s;
      position: relative;
    }
  }

  .projectcard:hover {
    transform: scale(1.02);
    box-shadow: 0px 0px 10px 2px #5a5a5a;
  }
  
  .projectcard__img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 20px 20px 0 0;
    background-color: rgba(137, 43, 226, 0);
  }
  
  .projectcard__descr-wrapper {
    padding: 15px;
    display: grid;
  }
  
  .projectcard__title {
    color: var(--font-color);
    text-align: center;
    margin-bottom: 15px;
    font-weight: 900;
    font-size: 16px;
  }
  
  .projectcard__descr {
    color: var(--font-color);
  }
  
  .svg {
    width: 25px;
    height: 25px;
    transform: translateY(25%);
    fill: var(--font-color);
  }
  
  .projectcard__links {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    align-self: flex-end;
  }
  
  .projectcard__links .link {
    color: var(--font-color);
    font-weight: 600;
    font-size: 15px;
    text-decoration: none;
  }
  
  .projectcard__links .link:hover {
    text-decoration: underline;
  }
  /* github button below */
  .btngt {
    display: grid;
    margin: auto;
    place-items: center;
    background: #e3edf7;
    width: 120px;
    padding: 1.4em;
    border-radius: 10px;
    box-shadow: 6px 6px 10px -1px rgba(0,0,0,0.15),
            -6px -6px 10px -1px rgba(255,255,255,0.7);
    border: 1px solid rgba(0,0,0,0);
    cursor: pointer;
    transition: transform 0.5s;
  }
  
  .btngt:hover {
    box-shadow: inset 4px 4px 6px -1px rgba(0,0,0,0.2),
            inset -4px -4px 6px -1px rgba(255,255,255,0.7),
            -0.5px -0.5px 0px rgba(255,255,255,1),
            0.5px 0.5px 0px rgba(0,0,0,0.15),
            0px 12px 10px -10px rgba(0,0,0,0.05);
    border: 1px solid rgba(0,0,0,0.1);
    transform: translateY(0.5em);
  }
  
  .btngt svg {
    transition: transform 0.5s;
  }
  
  .btngt:hover svg {
    transform: scale(0.9);
    fill: #333333;
  }
  @media screen and (max-width: 768px) {
    .formore{
      /* margin: auto; */
      margin-top: 27px;
      /* margin-right: -50px; */
      font-size: 1rem;
      align-items: center;
      margin-left: 240px;
   }
  }
  
  /* Experience */

  .tableexp{
    align-items: center;
    justify-content: center;
    margin: auto;
  }
  /*  */
  .macbook {
    width: 150px;
    height: 96px;
    position: relative;
    /* left: 50%; */
    /* top: 50%; */
    margin: -85px -20px 40px 8px;
    perspective: 500px;
  }
  
  .shadow {
    position: absolute;
    width: 60px;
    height: 0px;
    left: 40px;
    top: 160px;
    transform: rotateX(80deg) rotateY(0deg) rotateZ(0deg);
    box-shadow: 0 0 60px 40px rgba(0,0,0,0.3);
    animation: shadow infinite 7s ease;
  }
  
  .inner {
    z-index: 20;
    position: absolute;
    width: 150px;
    height: 96px;
    left: 0;
    top: 0;
    transform-style: preserve-3d;
    transform: rotateX(-20deg) rotateY(0deg) rotateZ(0deg);
    animation: rotate infinite 7s ease;
  }
  
  .screen {
    width: 150px;
    height: 96px;
    position: absolute;
    left: 0;
    bottom: 0;
    border-radius: 7px;
    background: #ddd;
    transform-style: preserve-3d;
    transform-origin: 50% 93px;
    transform: rotateX(0deg) rotateY(0deg) rotateZ(0deg);
    animation: lid-screen infinite 7s ease;
    background-image: linear-gradient(45deg, rgba(0,0,0,0.34) 0%,rgba(0,0,0,0) 100%);
    background-position: left bottom;
    background-size: 300px 300px;
    box-shadow: inset 0 3px 7px rgba(255,255,255,0.5);
  }
  
  .screen .logo {
    position: absolute;
    width: 20px;
    height: 24px;
    left: 50%;
    top: 50%;
    margin: -12px 0 0 -10px;
    transform: rotateY(180deg) translateZ(0.1px);
  }
  
  .screen .face-one {
    width: 150px;
    height: 96px;
    position: absolute;
    left: 0;
    bottom: 0;
    border-radius: 7px;
    background: #d3d3d3;
    transform: translateZ(2px);
    background-image: linear-gradient(45deg,rgba(0,0,0,0.24) 0%,rgba(0,0,0,0) 100%);
  }
  
  .screen .face-one .camera {
    width: 3px;
    height: 3px;
    border-radius: 100%;
    background: #000;
    position: absolute;
    left: 50%;
    top: 4px;
    margin-left: -1.5px;
  }
  
  .screen .face-one .display {
    width: 130px;
    height: 74px;
    margin: 10px;
    background-color: #000;
    background-size: 100% 100%;
    border-radius: 1px;
    position: relative;
    box-shadow: inset 0 0 2px rgba(0,0,0,1);
  }
  
  .screen .face-one .display .shade {
    position: absolute;
    left: 0;
    top: 0;
    width: 130px;
    height: 74px;
    background: linear-gradient(-135deg, rgba(255,255,255,0) 0%,rgba(255,255,255,0.1) 47%,rgba(255,255,255,0) 48%);
    animation: screen-shade infinite 7s ease;
    background-size: 300px 200px;
    background-position: 0px 0px;
  }
  
  .screen .face-one span {
    position: absolute;
    top: 85px;
    left: 57px;
    font-size: 6px;
    color: #666
  }
  
  .macbody {
    width: 150px;
    height: 96px;
    position: absolute;
    left: 0;
    bottom: 0;
    border-radius: 7px;
    background: #cbcbcb;
    transform-style: preserve-3d;
    transform-origin: 50% bottom;
    transform: rotateX(-90deg);
    animation: lid-macbody infinite 7s ease;
    background-image: linear-gradient(45deg, rgba(0,0,0,0.24) 0%,rgba(0,0,0,0) 100%);
  }
  
  .macbody .face-one {
    width: 150px;
    height: 96px;
    position: absolute;
    left: 0;
    bottom: 0;
    border-radius: 7px;
    transform-style: preserve-3d;
    background: #dfdfdf;
    animation: lid-keyboard-area infinite 7s ease;
    transform: translateZ(-2px);
    background-image: linear-gradient(30deg, rgba(0,0,0,0.24) 0%,rgba(0,0,0,0) 100%);
  }
  
  .macbody .touchpad {
    width: 40px;
    height: 31px;
    position: absolute;
    left: 50%;
    top: 50%;
    border-radius: 4px;
    margin: -44px 0 0 -18px;
    background: #cdcdcd;
    background-image: linear-gradient(30deg, rgba(0,0,0,0.24) 0%,rgba(0,0,0,0) 100%);
    box-shadow: inset 0 0 3px #888;
  }
  
  .macbody .keyboard {
    width: 130px;
    height: 45px;
    position: absolute;
    left: 7px;
    top: 41px;
    border-radius: 4px;
    transform-style: preserve-3d;
    background: #cdcdcd;
    background-image: linear-gradient(30deg, rgba(0,0,0,0.24) 0%,rgba(0,0,0,0) 100%);
    box-shadow: inset 0 0 3px #777;
    padding: 0 0 0 2px;
  }
  
  .keyboard .key {
    width: 6px;
    height: 6px;
    background: #444;
    float: left;
    margin: 1px;
    transform: translateZ(-2px);
    border-radius: 2px;
    box-shadow: 0 -2px 0 #222;
    animation: keys infinite 7s ease;
  }
  
  .key.space {
    width: 45px;
  }
  
  .key.f {
    height: 3px;
  }
  
  .macbody .pad {
    width: 5px;
    height: 5px;
    background: #333;
    border-radius: 100%;
    position: absolute;
  }
  
  .pad.one {
    left: 20px;
    top: 20px;
  }
  
  .pad.two {
    right: 20px;
    top: 20px;
  }
  
  .pad.three {
    right: 20px;
    bottom: 20px;
  }
  
  .pad.four {
    left: 20px;
    bottom: 20px;
  }
  
  @keyframes rotate {
    0% {
      transform: rotateX(-20deg) rotateY(0deg) rotateZ(0deg);
    }
  
    5% {
      transform: rotateX(-20deg) rotateY(-20deg) rotateZ(0deg);
    }
  
    20% {
      transform: rotateX(30deg) rotateY(200deg) rotateZ(0deg);
    }
  
    25% {
      transform: rotateX(-60deg) rotateY(150deg) rotateZ(0deg);
    }
  
    60% {
      transform: rotateX(-20deg) rotateY(130deg) rotateZ(0deg);
    }
  
    65% {
      transform: rotateX(-20deg) rotateY(120deg) rotateZ(0deg);
    }
  
    80% {
      transform: rotateX(-20deg) rotateY(375deg) rotateZ(0deg);
    }
  
    85% {
      transform: rotateX(-20deg) rotateY(357deg) rotateZ(0deg);
    }
  
    87% {
      transform: rotateX(-20deg) rotateY(360deg) rotateZ(0deg);
    }
  
    100% {
      transform: rotateX(-20deg) rotateY(360deg) rotateZ(0deg);
    }
  }
  
  @keyframes lid-screen {
    0% {
      transform: rotateX(0deg);
      background-position: left bottom;
    }
  
    5% {
      transform: rotateX(50deg);
      background-position: left bottom;
    }
  
    20% {
      transform: rotateX(-90deg);
      background-position: -150px top;
    }
  
    25% {
      transform: rotateX(15deg);
      background-position: left bottom;
    }
  
    30% {
      transform: rotateX(-5deg);
      background-position: right top;
    }
  
    38% {
      transform: rotateX(5deg);
      background-position: right top;
    }
  
    48% {
      transform: rotateX(0deg);
      background-position: right top;
    }
  
    90% {
      transform: rotateX(0deg);
      background-position: right top;
    }
  
    100% {
      transform: rotateX(0deg);
      background-position: right center;
    }
  }
  
  @keyframes lid-macbody {
    0% {
      transform: rotateX(-90deg);
    }
  
    50% {
      transform: rotateX(-90deg);
    }
  
    100% {
      transform: rotateX(-90deg);
    }
  }
  
  @keyframes lid-keyboard-area {
    0% {
      background-color: #dfdfdf;
    }
  
    50% {
      background-color: #bbb;
    }
  
    100% {
      background-color: #dfdfdf;
    }
  }
  
  @keyframes screen-shade {
    0% {
      background-position: -20px 0px;
    }
  
    5% {
      background-position: -40px 0px;
    }
  
    20% {
      background-position: 200px 0;
    }
  
    50% {
      background-position: -200px 0;
    }
  
    80% {
      background-position: 0px 0px;
    }
  
    85% {
      background-position: -30px 0;
    }
  
    90% {
      background-position: -20px 0;
    }
  
    100% {
      background-position: -20px 0px;
    }
  }
  
  @keyframes keys {
    0% {
      box-shadow: 0 -2px 0 #222;
    }
  
    5% {
      box-shadow: 1 -1px 0 #222;
    }
  
    20% {
      box-shadow: -1px 1px 0 #222;
    }
  
    25% {
      box-shadow: -1px 1px 0 #222;
    }
  
    60% {
      box-shadow: -1px 1px 0 #222;
    }
  
    80% {
      box-shadow: 0 -2px 0 #222;
    }
  
    85% {
      box-shadow: 0 -2px 0 #222;
    }
  
    87% {
      box-shadow: 0 -2px 0 #222;
    }
  
    100% {
      box-shadow: 0 -2px 0 #222;
    }
  }
  
  @keyframes shadow {
    0% {
      transform: rotateX(80deg) rotateY(0deg) rotateZ(0deg);
      box-shadow: 0 0 60px 40px rgba(0,0,0,0.3);
    }
  
    5% {
      transform: rotateX(80deg) rotateY(10deg) rotateZ(0deg);
      box-shadow: 0 0 60px 40px rgba(0,0,0,0.3);
    }
  
    20% {
      transform: rotateX(30deg) rotateY(-20deg) rotateZ(-20deg);
      box-shadow: 0 0 50px 30px rgba(0,0,0,0.3);
    }
  
    25% {
      transform: rotateX(80deg) rotateY(-20deg) rotateZ(50deg);
      box-shadow: 0 0 35px 15px rgba(0,0,0,0.1);
    }
  
    60% {
      transform: rotateX(80deg) rotateY(0deg) rotateZ(-50deg) translateX(30px);
      box-shadow: 0 0 60px 40px rgba(0,0,0,0.3);
    }
  
    100% {
      box-shadow: 0 0 60px 40px rgba(0,0,0,0.3);
    }
  }
  

  /*  */


  .timelineexp {
    position: relative;
    width: 660px;
    margin: 0 auto;
    margin-top: 20px;
    padding: 1em 0;
    list-style-type: none;
  }
  
  .timelineexp:before {
    position: absolute;
    left: 50%;
    top: 0;
    content: ' ';
    display: block;
    width: 6px;
    height: 100%;
    margin-left: -3px;
    background: rgb(80,80,80);
    background: -moz-linear-gradient(top, rgba(80,80,80,0) 0%, rgb(80,80,80) 8%, rgb(80,80,80) 92%, rgba(80,80,80,0) 100%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(30,87,153,1)), color-stop(100%,rgba(125,185,232,1)));
    background: -webkit-linear-gradient(top, rgba(80,80,80,0) 0%, rgb(80,80,80) 8%, rgb(80,80,80) 92%, rgba(80,80,80,0) 100%);
    background: -o-linear-gradient(top, rgba(80,80,80,0) 0%, rgb(80,80,80) 8%, rgb(80,80,80) 92%, rgba(80,80,80,0) 100%);
    background: -ms-linear-gradient(top, rgba(80,80,80,0) 0%, rgb(80,80,80) 8%, rgb(80,80,80) 92%, rgba(80,80,80,0) 100%);
    background: linear-gradient(to bottom, rgba(80,80,80,0) 0%, rgb(80,80,80) 8%, rgb(80,80,80) 92%, rgba(80,80,80,0) 100%);
    
    z-index: 5;
  }
  
  .timelineexp li {
    padding: 1em 0;
  }
  
  .timelineexp li:after {
    content: "";
    display: block;
    height: 0;
    clear: both;
    visibility: hidden;
  }
  
  .direction-l {
    position: relative;
    width: 300px;
    float: left;
    text-align: right;
  }
  
  .direction-r {
    position: relative;
    width: 300px;
    float: right;
  }
  
  .flag-wrapper {
    position: relative;
    display: inline-block;
    
    text-align: center;
  }
  
  .flag {
    position: relative;
    display: inline;
    background: rgb(248,248,248);
    padding: 6px 10px;
    border-radius: 5px;
    
    font-weight: 600;
    text-align: left;
  }
  
  .direction-l .flag {
    -webkit-box-shadow: -1px 1px 1px rgba(0,0,0,0.15), 0 0 1px rgba(0,0,0,0.15);
    -moz-box-shadow: -1px 1px 1px rgba(0,0,0,0.15), 0 0 1px rgba(0,0,0,0.15);
    box-shadow: -1px 1px 1px rgba(0,0,0,0.15), 0 0 1px rgba(0,0,0,0.15);
  }
  
  .direction-r .flag {
    -webkit-box-shadow: 1px 1px 1px rgba(0,0,0,0.15), 0 0 1px rgba(0,0,0,0.15);
    -moz-box-shadow: 1px 1px 1px rgba(0,0,0,0.15), 0 0 1px rgba(0,0,0,0.15);
    box-shadow: 1px 1px 1px rgba(0,0,0,0.15), 0 0 1px rgba(0,0,0,0.15);
  }
  
  .direction-l .flag:before,
  .direction-r .flag:before {
    position: absolute;
    top: 50%;
    right: -40px;
    content: ' ';
    display: block;
    width: 12px;
    height: 12px;
    margin-top: -10px;
    background: #fff;
    border-radius: 10px;
    border: 4px solid rgb(255,80,80);
    z-index: 10;
  }
  
  .direction-r .flag:before {
    left: -40px;
  }
  
  .direction-l .flag:after {
    content: "";
    position: absolute;
    left: 100%;
    top: 50%;
    height: 0;
    width: 0;
    margin-top: -8px;
    border: solid transparent;
    border-left-color: rgb(248,248,248);
    border-width: 8px;
    pointer-events: none;
  }
  
  .direction-r .flag:after {
    content: "";
    position: absolute;
    right: 100%;
    top: 50%;
    height: 0;
    width: 0;
    margin-top: -8px;
    border: solid transparent;
    border-right-color: rgb(248,248,248);
    border-width: 8px;
    pointer-events: none;
  }
  
  .time-wrapper {
    display: inline;
    
    line-height: 1em;
    font-size: 0.66666em;
    color: rgb(250,80,80);
    vertical-align: middle;
  }
  
  .direction-l .time-wrapper {
    float: left;
  }
  
  .direction-r .time-wrapper {
    float: right;
  }
  
  .time {
    display: inline-block;
    padding: 4px 6px;
    background: rgb(248,248,248);
  }
  
  .desc {
    margin: 1em 0.75em 0 0;
    
    font-size: 0.77777em;
    font-style: italic;
    line-height: 1.5em;
  }
  
  .direction-r .desc {
    margin: 1em 0 0 0.75em;
  }
  
  /* ================ Timeline Media Queries ================ */
  
  @media screen and (max-width: 768px) {

  .timeline{
    /* background-color: #0974f1; */
    width: 100%;
  }
  
  .timelineexp {
       width: 100%;
      padding: 4em 0 1em 0;
  }
  
  .timelineexp li {
      padding: 2em 0;
  }
  
  .direction-l,
  .direction-r {
      float: none;
      width: 100%;
  
      text-align: center;
  }
  
  .flag-wrapper {
      text-align: center;
  }
  
  .flag {
      background: rgb(255,255,255);
      z-index: 15;
  }
  
  .direction-l .flag:before,
  .direction-r .flag:before {
    position: absolute;
    top: -30px;
      left: 50%;
      content: ' ';
      display: block;
      width: 12px;
      height: 12px;
      margin-left: -9px;
      background: #fff;
      border-radius: 10px;
      border: 4px solid rgb(255,80,80);
      z-index: 10;
  }
  
  .direction-l .flag:after,
  .direction-r .flag:after {
      content: "";
      position: absolute;
      left: 50%;
      top: -8px;
      height: 0;
      width: 0;
      margin-left: -8px;
      border: solid transparent;
      border-bottom-color: rgb(255,255,255);
      border-width: 8px;
      pointer-events: none;
  }
  
  .time-wrapper {
      display: block;
      position: relative;
      margin: 4px 0 0 0;
      z-index: 14;
  }
  
  .direction-l .time-wrapper {
      float: none;
  }
  
  .direction-r .time-wrapper {
      float: none;
  }
  
  .desc {
      position: relative;
      margin: 1em 0 0 0;
      padding: 1em;
      background: #e8e8e842;
      -webkit-box-shadow: 0 0 1px rgba(0, 0, 0, 0);
      -moz-box-shadow: 0 0 1px rgba(0, 0, 0, 0);
      box-shadow: 0 0 1px rgba(0, 0, 0, 0);
      
    z-index: 15;
  }
  
  .direction-l .desc,
  .direction-r .desc {
      position: relative;
      margin: 1em 1em 0 1em;
      padding: 1em;
      
    z-index: 15;
  }
  
  }

  .techstack{
    align-items: center;
    justify-content: center;
    margin: auto;
    /* margin-left: 30%; */
    display: flex;
    padding: 0;
    box-sizing: border-box;
  }
  :root{
    --bg-clr: #e8e8e8;
  }
  .techstack .body {
    min-height: 100svh;
    display: grid;
    place-content: center;
    background-color: var(--bg-clr);
  }
  
  .carousel-wrapper {
    --width: 100px;
    --gap: 0;
    --num-items: 10;
    --ani-offset: calc(var(--width) * var(--num-items) * -1);
    --ani-speed: 10s;
    
    width: 400px;
    overflow: hidden;
    position: relative;
  }
  .carousel-wrapper::before,
  .carousel-wrapper::after{
    content: '';
    position: absolute;
    width: 20%;
    height: 100%;
    z-index: 1;
    top: 0;
  }
  .carousel-wrapper::before{
    left: 0;
    background-image: linear-gradient(to right,var(--bg-clr) 0%,transparent 50%);
  }
  .carousel-wrapper::after{
    right: 0;
    background-image: linear-gradient(to left,var(--bg-clr) 0%,transparent 50%);
  }
  
  .carousel {
    display: flex;
    align-items: center;
    animation: slide var(--ani-speed) linear infinite;
  }
  .item{
    flex: 1 0 var(--width);
    text-align: center;
    padding:1rem;
  }
 
  .item > img{
    width: 100%;
    height: auto;
    object-fit: cover;
  }
  
  @keyframes slide {
    100% {
      transform: translateX(var(--ani-offset));
    }
  }
  /* Skill percentage */

  @media screen and (max-width: 768px) {

    .percentage{
    }

    .skillcontainer{
    }
   
  }


  .skillcontainer {
    display: none;
    background: #e8e8e8;
    border: 1px solid #e8e8e8;
    box-shadow: 6px 6px 12px #c5c5c5, -6px -6px 12px #ffffff;
    position: relative;
    /* margin-left: 30%; */
    margin-left: 30%;
    align-items: center;
    justify-content: center;
    max-width: 500px;
    width: 100%;
    padding: 10px 20px;
    border-radius: 7px;
  }

  @media screen and (max-width: 768px) {
    .skillcontainer {
      max-width: 500px;
      margin-left: 8%;
      margin-top: 10%;
    }
    
  }
  
  .skillcontainer .skill-box {
    width: 100%;
    margin: 25px 0;
  }
  
  .skill-box .title {
    display: block;
    font-size: 14px;
    font-weight: 600;
    color: rgb(54, 54, 54);
  }
  
  .skill-box .skill-bar {
    height: 8px;
    width: 100%;
    border-radius: 6px;
    margin-top: 6px;
    background: rgba(236, 236, 236, 0.1);
  }
  
  .skill-bar .skill-per {
    position: relative;
    display: block;
    height: 100%;
    width: 90%;
    border-radius: 6px;
    background: rgb(78, 78, 78);
    animation: progress 0.4s ease-in-out forwards;
    opacity: 0;
  }
  
  .skill-per.html {
    /*progreso de las diferentes lenguajes*/
    width: 100%;
    animation-delay: 0.1s;
  }
  
  .skill-per.scss {
    /*progreso de las diferentes lenguajes*/
    width: 80%;
    animation-delay: 0.1s;
  }
  
  .skill-per.Boostrap {
    /*progreso de las diferentes lenguajes*/
    width: 60%;
    animation-delay: 0.2s;
  }

  .skill-per.flutter {
    /*progreso de las diferentes lenguajes*/
    width: 65%;
    animation-delay: 0.2s;
  }

  .skill-per.dart {
    /*progreso de las diferentes lenguajes*/
    width: 65%;
    animation-delay: 0.2s;
  }

  .skill-per.js {
    /*progreso de las diferentes lenguajes*/
    width: 55%;
    animation-delay: 0.2s;
  }

  .skill-per.python {
    /*progreso de las diferentes lenguajes*/
    width: 85%;
    animation-delay: 0.2s;
  }

  .skill-per.java {
    /*progreso de las diferentes lenguajes*/
    width: 70%;
    animation-delay: 0.2s;
  }

  .skill-per.cpp {
    /*progreso de las diferentes lenguajes*/
    width: 60%;
    animation-delay: 0.2s;
  }
  
  @keyframes progress {
    0% {
      width: 0;
      opacity: 1;
    }
  
    100% {
      opacity: 1;
    }
  }
  
  .skill-per .tooltip {
    position: absolute;
    right: -14px;
    top: -28px;
    font-size: 9px;
    font-weight: 500;
    color: rgb(255, 255, 255);
    font-weight: bold;
    padding: 2px 6px;
    border-radius: 3px;
    background: #181818;
    z-index: 1;
  }
  
  .tooltip::before {
    content: "";
    position: absolute;
    left: 50%;
    bottom: -2px;
    height: 10px;
    width: 10px;
    z-index: -1;
    background-color: rgb(0, 0, 0);
    transform: translateX(-50%) rotate(45deg);
  }


  /* Git Commits and Streak start*/
  .commits{
    margin-top: 50px;
    margin-left: auto;
    margin-right: auto;
    /* background-color: #000; */
  }

  .streak{
    margin-top: 30px;
    margin-left: auto;
    margin-right: auto;
  }

  /* .commits img{
    width: 100%;
    background-color: #000;
  } */

  @media screen and (max-width: 768px) {
    .commits img{
      margin: auto;
      width: 450px;
    }
    .commits{
      margin-top: 50px;
      margin-left: auto;
      margin-right: auto;
      /* background-color: #000; */
    }
  
    .streak{
      margin-top: 30px;
      margin-left: auto;
      margin-right: auto;
    }
  }
  /* Git Commits and Streak end */


  /* follow me on icons */
  
  .wrapper {
    display: inline-flex;
    list-style: none;
    height: 120px;
    width: 100%;
    padding-top: 40px;
    font-family: "Poppins", sans-serif;
    justify-content: center;
  }
  
  .wrapper .icon {
    position: relative;
    /* background: #fff; */
    background: #e8e8e8;
    border: 1px solid #e8e8e8;
    /* box-shadow: 6px 6px 12px #c5c5c5, -6px -6px 12px #ffffff; */
    border-radius: 50%;
    margin: 10px;
    width: 60px;
    height: 60px;
    font-size: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  }
  
  .wrapper .tooltip {
    position: absolute;
    top: 0;
    font-size: 14px;
    background: #fff;
    color: #fff;
    padding: 5px 8px;
    border-radius: 5px;
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
    opacity: 0;
    pointer-events: none;
    transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  }
  
  .wrapper .tooltip::before {
    position: absolute;
    content: "";
    height: 8px;
    width: 8px;
    background: #fff;
    bottom: -3px;
    left: 50%;
    transform: translate(-50%) rotate(45deg);
    transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  }
  
  .wrapper .icon:hover .tooltip {
    top: -45px;
    opacity: 1;
    visibility: visible;
    pointer-events: auto;
  }
  
  .wrapper .icon:hover span,
  .wrapper .icon:hover .tooltip {
    text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.1);
  }
  
  .wrapper .facebook:hover,
  .wrapper .facebook:hover .tooltip,
  .wrapper .facebook:hover .tooltip::before {
    background: #1877f2;
    color: #fff;
  }
  
  .wrapper .linkedin:hover,
  .wrapper .linkedin:hover .tooltip,
  .wrapper .linkedin:hover .tooltip::before {
    background: #1da1f2;
    color: #fff;
  }
  
  .wrapper .instagram:hover,
  .wrapper .instagram:hover .tooltip,
  .wrapper .instagram:hover .tooltip::before {
    background: #e4405f;
    color: #fff;
  }

  .wrapper .github:hover,
  .wrapper .github:hover .tooltip,
  .wrapper .github:hover .tooltip::before {
    background: #151515;
    color: #fff;
  }

  .wrapper .whatsapp:hover,
  .wrapper .whatsapp:hover .tooltip,
  .wrapper .whatsapp:hover .tooltip::before {
    background: #4cd655;
    color: #fff;
  }

  .wrapper .phone:hover,
  .wrapper .phone:hover .tooltip,
  .wrapper .phone:hover .tooltip::before {
    background: #1d8cd6;
    color: #fff;
  }

  .wrapper .gmail:hover,
  .wrapper .gmail:hover .tooltip,
  .wrapper .gmail:hover .tooltip::before {
    background: #dd2b1b;
    color: #fff;
  }

  .followme{
    margin: auto;
    margin-top: 17px;
    font-size: 1rem;
    align-items: center;
    margin-left: 530px;
  }

  @media screen and (max-width: 768px) {
    .followme{
      margin: auto;
      margin-top: 17px;
      font-size: 1rem;
      align-items: center;
      margin-left: 250px;
    }
  }

  .eml{
    /* margin: auto; */
    /* text-decoration:#090909; */
    border-color: #090909;
    /* background-color: #090909; */
    margin-top: 17px;
    font-size: 1rem;
    align-items: center;
    margin-left: 530px;
  }




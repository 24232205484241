.hero-img{
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    height: 100vh;
    position: relative;
}

.hero-img::before{
    content: "";
    background-image: url("https://c4.wallpaperflare.com/wallpaper/913/752/910/digital-art-blue-leaves-pattern-wallpaper-preview.jpg"); 
    background-size: cover;
    background-position: center top;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;

}

.content{
    position: absolute;
    transform: translate(-50%,-50%);
    top: 50%;
    left: 50%;
    text-align: center;
}

.about{
    width: 100%;
    margin: 3rem 0;
    align-items: center;
    justify-content: center;
    
}    

.heading{
    width: 100%;
    height: 100%;
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.hero-img h1{
    font-size: 2.4rem;
}
.hero-img p{
    font-size: 1.4rem;
    text-align: center;
}
@media screen and(max-width: 640px){
    .hero-img h1{
        font-size: 2rem;
    }  
}
